<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-50">
        <chart-card
          v-if="chartLoaded"
          header-animation="false"
          :chart-data="pieChart.data"
          :chart-options="pieChart.options"
          chart-type="Pie"
          header-icon
          chart-inside-content
          background-color="blue"
        >
          <template slot="chartInsideHeader">
            <div class="card-icon">
              <md-icon>timeline</md-icon>
            </div>
            <h4 class="title">
              Source of Sales <small>(last 12 months)</small>
            </h4>
          </template>
          <template slot="footer">
            <div>
              <div
                v-for="(title, i) of pieChart.data.titles"
                :key="i"
                class="label"
              >
                <span :class="'label-'+(i+1)" /> {{ title }} ({{ pieChart.data.labels[i] }})
              </div>
            </div>
          </template>
        </chart-card>
      </div>

      <div class="md-layout-item md-size-50 md-small-size-100">
        <md-card class="">
          <md-card-header class="md-card-header-icon md-card-header-blue">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">
              Generate Report
            </h4>
          </md-card-header>

          <md-card-content>
            <form-date
              v-model="report_from"
              name="from"
              label="From..."
            />
            <form-date
              v-model="report_to"
              name="to"
              label="To..."
            />
            <md-button
              class="md-info md-block"
              @click="downloadReport"
            >
              Download
            </md-button>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { ChartCard } from '@/components';
import moment from 'moment';
import { FormDate } from '@/components/Inputs';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    ChartCard,
    FormDate,
  },
  props: [],
  data() {
    return {
      report_from: null,
      report_to: null,
      pieChart: {
        data: {
          labels: [],
          series: [],
          titles: [],
        },
        options: {
          height: '230px',
        },
      },
      chartLoaded: false,
      loading: false,
    };
  },
  mounted() {
    this.getSources();
  },
  methods: {
    getSources() {
      this.request(this.$API.SOURCE_OF_SALES, 'get', null, ({ data }) => {
        data.forEach((x, i) => {
          this.pieChart.data.labels.push(`${x.percentage.toFixed(2)}%`);
          this.pieChart.data.series.push(x.percentage);
          this.pieChart.data.titles.push(x.source);

          if ((i + 1) === data.length) this.chartLoaded = true;
        });
      });
    },
    downloadReport() {
      this.loading = true;
      this.request(this.$API.SOURCE_OF_SALES, 'download', {
        download: true,
        from: this.report_from,
        to: this.report_to,
      }, ({ data }) => {
        const filename = `Source_of_sales_report_${moment().format('YYYY_MM_DD')}.xlsx`;
        downloadFile(data, filename);
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.success {
  color: green;
}
.error {
  color: red
}
.label {
  span[class^="label-"] {
    height: 20px;
    width: 20px;
    display: inline-block;
    vertical-align: sub;
  }

  .label-1 {
    background-color: #2a9146;
  }
  .label-2 {
    background-color: red;
  }
  .label-3 {
    background-color: #e2eb38;
  }
  .label-4 {
    background-color: #eb8c1f;
  }
  .label-5 {
    background-color: #1febd0;
  }
  .label-6 {
    background-color: #1f22eb;
  }
  .label-7 {
    background-color: #6d1feb;
  }
  .label-8 {
    background-color: #9b11a0;
  }
}
</style>
<style scoped>
::v-deep .ct-series-a .ct-slice-pie {
  fill: #2a9146;
}
::v-deep .ct-series-b .ct-slice-pie {
  fill: red;
}
::v-deep .ct-series-c .ct-slice-pie {
  fill: #e2eb38;
}
::v-deep .ct-series-d .ct-slice-pie {
  fill: #eb8c1f;
}
::v-deep .ct-series-e .ct-slice-pie {
  fill: #1febd0;
}
::v-deep .ct-series-f .ct-slice-pie {
  fill: #1f22eb;
}
::v-deep .ct-series-g .ct-slice-pie {
  fill: #6d1feb;
}
::v-deep .ct-series-h .ct-slice-pie {
  fill: #9b11a0;
}
</style>
